import React from 'react'
import {Pressable, Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {useNavigation} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'

import {NavigationProp} from '#/lib/routes/types'
import {isMobileWeb} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {useFriendQueryPendingReqNum} from '#/state/queries/connections'
import {colors} from 'lib/styles'
import {useTelegramLink} from '#/view/com/modals/TelegramModal/hooks'
import TagFilter from '#/view/com/tags/TagFilter'
import {Tag} from '#/view/com/tags/TagItem'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {SearchInput} from '#/view/com/util/forms/SearchInput'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {useDialogControl} from '#/components/Dialog'
import {CircleCreateTagDialog} from '#/components/dialogs/CircleCreateTag'
import {CircleUserInfoDialog} from '#/components/dialogs/CircleUserInfo'
import {MessageNotif} from '#/components/icons/MessageNotif'
import {Refresh} from '#/components/icons/Refresh'
import {Loader} from '#/components/Loader'
import ContactList from '../Telegram/ContactList'
import {CircleFromType, ContactItemProps} from '.'
import {
  removeDuplicatesWithSet,
  telegramContactList,
  useSipzContactList,
  useTagList,
  useTelegramContactList,
} from './hooks'

interface IProps {
  query?: string
  type?: CircleFromType
  setType?: (type: CircleFromType) => void
}
export function FriendPanel(props: IProps) {
  const t = useTheme()

  const queryClient = useQueryClient()
  const control = useDialogControl()
  const tagUpdateControl = useDialogControl()
  const navigation = useNavigation<NavigationProp>()
  const {saveContacts} = useTelegramLink()
  const {openModal} = useModalControls()

  const [selected, setSelected] = React.useState<ContactItemProps>()
  const [selectedTags, setSelectedTags] = React.useState<Tag[]>([])
  const [filterTags, setFilterTags] = React.useState<Tag[]>([])
  const [filterTeles, setFilterTeles] = React.useState<string[]>([])
  const [query, setQuery] = React.useState('')
  const [selectedFromType, setSelectedFromType] =
    React.useState<CircleFromType>(props?.type ?? 'telegram')
  const [refreshing, setRefreshing] = React.useState(false)

  const {data: reqNum} = useFriendQueryPendingReqNum()
  const {
    status: teleStatus,
    data: teleContacts = [],
    error: teleError,
  } = useTelegramContactList({
    circleId: -1,
    query,
    tags: filterTags,
  })
  const {
    status: sipzStatus,
    data: sipzContacts = [],
    error: sipzError,
  } = useSipzContactList({
    circleId: -1,
    query,
    tags: filterTags,
  })
  const {data: tags = []} = useTagList()

  const onClickItem = (user: ContactItemProps) => {
    control.open()
    setSelected(user)
    setSelectedTags(user?.tags || [])
  }

  const onConfirmFilterTags = (_tags: Tag[], teles?: string[]) => {
    setFilterTags(_tags)
    setFilterTeles(teles ?? [])
    queryClient.invalidateQueries({
      queryKey: [telegramContactList, {tags: _tags}],
    })
  }

  const onChangeQuery = (v: string) => {
    setQuery(v)
  }

  const onPressCancelSearch = () => {
    setQuery('')
  }

  const onSubmitQuery = () => {
    queryClient.invalidateQueries({queryKey: [telegramContactList, {query}]})
  }

  const data = removeDuplicatesWithSet(
    selectedFromType === 'telegram' ? teleContacts : sipzContacts,
    'id',
  )
  const status = selectedFromType === 'telegram' ? teleStatus : sipzStatus
  const error = selectedFromType === 'telegram' ? teleError : sipzError

  let content
  if (status === 'pending') {
    content = (
      <View
        style={[a.flex_1, a.h_full, a.align_center, a.justify_center, a.p_2xl]}>
        <Loader size="xl" />
      </View>
    )
  } else if (error) {
    content = (
      <View>
        <Text style={[t.atoms.text]}>{error?.message}</Text>
      </View>
    )
  } else if (data?.length === 0) {
    content = (
      <EmptyBox
        icon="heart"
        message={'No contacts yet'}
        viewStyles={{paddingTop: 20, paddingBottom: 20}}
      />
    )
  } else {
    let dataValue = data
    if (selectedFromType === 'telegram') {
      dataValue = data
        .filter(o => {
          if (filterTeles?.length === 1) {
            if (filterTeles.indexOf('registered') >= 0) {
              return o?.isAdded && o?.degree === 1
            } else {
              return (
                !(o?.isAdded && o?.degree === 1) && !o?.isAdded && o?.isInvite
              )
            }
          } else {
            return (
              (o?.isAdded && o?.degree === 1) || (!o?.isAdded && o?.isInvite)
            )
          }
        })
        ?.sort((a, b) => (b?.isAdded ? 1 : 0) - (a?.isAdded ? 1 : 0))
    }
    content = (
      <ContactList
        users={dataValue}
        showCheckbox={false}
        onClickItem={onClickItem}
        filterTags={filterTags}
      />
    )
  }

  const renderHeaderBtn = React.useCallback(() => {
    return (
      <View style={[a.gap_md, a.flex_row, a.align_center]}>
        <Pressable
          accessibilityRole="button"
          role="button"
          onPress={() => {
            navigation.navigate('AddFriend')
          }}>
          <FontAwesomeIcon icon={'plus'} color={t.atoms.text.color} size={20} />
        </Pressable>
        <Pressable
          accessibilityRole="button"
          role="button"
          onPress={() => {
            navigation.navigate('FriendRequestList')
          }}>
          <View style={[a.flex_1, a.flex_row, a.gap_md]}>
            <MessageNotif style={{color: t.atoms.text.color}} />
            {reqNum && reqNum > 0 ? (
              <Text
                style={{
                  position: 'absolute',
                  top: -5,
                  right: -6,
                  backgroundColor: colors.bubble,
                  color: colors.white,
                  fontSize: 10,
                  paddingHorizontal: 6,
                  paddingVertical: 2,
                  borderRadius: 15,
                }}>
                {reqNum}
              </Text>
            ) : (
              ''
            )}
          </View>
        </Pressable>
      </View>
    )
  }, [navigation, reqNum, t.atoms.text.color])

  const reloadTelegramContacts = React.useCallback(async () => {
    if (!refreshing) {
      try {
        setRefreshing(true)
        await saveContacts()
        queryClient.invalidateQueries({queryKey: telegramContactList})
      } catch (err: any) {
        if (err?.code === 401) {
          openModal({name: 'telegram'})
        }
        console.log(err)
      } finally {
        setRefreshing(false)
      }
    }
  }, [refreshing, openModal, queryClient, saveContacts])

  const renderExtra = React.useCallback(() => {
    return (
      <Button label="refresh" onPress={reloadTelegramContacts}>
        <Refresh width={24} height={24} style={[t.atoms.text]} />
      </Button>
    )
  }, [reloadTelegramContacts, t.atoms.text])

  return (
    <CenteredView style={[{paddingBottom: isMobileWeb ? 90 : 0}]}>
      <ViewHeader
        title={`My Friends`}
        canGoBack
        showOnDesktop
        showBorder={false}
        renderButton={renderHeaderBtn}
      />
      <View style={[a.mt_md]}>
        <SearchInput
          query={query}
          onChangeQuery={onChangeQuery}
          onPressCancelSearch={onPressCancelSearch}
          onSubmitQuery={onSubmitQuery}
          style={[
            a.border,
            t.atoms.input_bg,
            t.atoms.input_border,
            a.mx_lg,
            {borderRadius: 10},
          ]}
        />
        <View style={[a.px_lg]}>
          <TagFilter
            selectedFromType={selectedFromType}
            onSelected={setSelectedFromType}
            onConfirm={onConfirmFilterTags}
            renderExtra={renderExtra}
          />
        </View>

        <View style={[a.px_md]}>
          {refreshing && (
            <View
              style={[
                a.align_center,
                a.justify_center,
                a.p_2xl,
                a.absolute,
                a.w_full,
                a.h_full,
              ]}>
              <Loader size="xl" />
            </View>
          )}
          {content}
        </View>
        <CircleUserInfoDialog
          control={control}
          createTagControl={tagUpdateControl}
          params={{
            selected,
            selectedTags,
            tags,
            fromType: selectedFromType,
          }}
        />
      </View>
      <CircleCreateTagDialog control={tagUpdateControl} />
    </CenteredView>
  )
}
