import React from 'react'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {ContactItemProps} from '#/view/screens/Circle'
import {useSipzContactList} from '#/view/screens/Circle/hooks'
import {atoms as a} from '#/alf'
import {ScrollView} from '../../modals/util'
import {EmptyBox} from '../../util/EmptyBox'
import {ItemProps} from '.'
import {Actor} from './Actor'

export const Friends = ({onPress, selecteds}: ItemProps) => {
  const {_} = useLingui()
  const {data} = useSipzContactList({circleId: -1, query: ''})

  const renderItem = ({item}: {item: ContactItemProps}) => {
    return (
      <Actor
        actor={{
          did: item?.id,
          handle: item?.username || '',
          displayName: item?.nickname,
          avatar: item?.avatar,
        }}
        onPress={onPress}
        style={[a.mt_md, a.justify_between]}
        isSelected={
          selecteds && selecteds?.findIndex(o => o?.did === item?.id) >= 0
        }
      />
    )
  }

  return (
    <ScrollView style={[a.mt_md, {height: 300}]}>
      <>
        {data?.length ? (
          data.map(item => renderItem({item}))
        ) : (
          <EmptyBox
            icon={''}
            message={_(msg`No content available at the moment`)}
          />
        )}
      </>
    </ScrollView>
  )
}
