import React, {useCallback} from 'react'
import {Pressable, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Popover} from 'react-native-modal-popover'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {
  useLinkProps,
  useNavigation,
  useNavigationState,
} from '@react-navigation/native'

import {isInvalidHandle} from '#/lib/strings/handles'
import {emitSoftReset} from '#/state/events'
import {useModalControls} from '#/state/modals'
import {useFetchHandle} from '#/state/queries/handle'
// import {useUnreadMessageCount} from '#/state/queries/messages/list-converations'
import {useUnreadNotifications} from '#/state/queries/notifications/unread'
import {useProfileQuery} from '#/state/queries/profile'
import {useSession} from '#/state/session'
import {ComposerOpts, useComposerControls} from '#/state/shell/composer'
import {usePostPreState} from '#/state/shell/post-pre-data'
import {useSelectedFeed} from '#/state/shell/selected-feed'
import {usePalette} from 'lib/hooks/usePalette'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {getCurrentRoute, isStateAtTabRoot, isTab} from 'lib/routes/helpers'
import {/*makeAssetsLink,*/ makeProfileLink} from 'lib/routes/links'
import {CommonNavigatorParams, NavigationProp} from 'lib/routes/types'
import {colors, s} from 'lib/styles'
// import {TelegramBindStep} from '#/view/com/modals/TelegramModal/config'
import {useTgStore} from '#/view/com/util/sdlStore/TgStore'
import {useTwStore} from '#/view/com/util/sdlStore/TwStore'
import {InviteIcon} from '#/view/icons/TopBarIcons'
// import {useProfileStore} from '#/view/com/util/sdlStore/ProfileStore'
// import {AssetsIcon} from '#/view/icons/TopBarIcons'
import {NavSignupCard} from '#/view/shell/NavSignupCard'
import {Link} from 'view/com/util/Link'
import {LoadingPlaceholder} from 'view/com/util/LoadingPlaceholder'
import {PressableWithHover} from 'view/com/util/PressableWithHover'
import {Text} from 'view/com/util/text/Text'
import {UserAvatar} from 'view/com/util/UserAvatar'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, Theme, useTheme} from '#/alf'
import {IconCircle} from '#/components/IconCircle'
import {
  BellOpen as Bell,
  BellOpen_Fill as BellFilled,
} from '#/components/icons/Bell'
import {
  Circle_Left_Nav as Circle,
  Circle_Left_Nav_Fill as CircleFill,
} from '#/components/icons/Circle'
import {Close_Stroke2_Corner2_Rounded as Close} from '#/components/icons/Close'
import {EditBig_Stroke2_Corner0_Rounded as EditBig} from '#/components/icons/EditBig'
import {
  HomeOpen as Home,
  HomeOpen_Fill as HomeFilled,
} from '#/components/icons/HomeOpen'
import {Mask, Mask_Fill as MaskFill} from '#/components/icons/Mask'
// import {MagnifyingGlass_Filled_Stroke2_Corner0_Rounded as MagnifyingGlassFilled} from '#/components/icons/MagnifyingGlass'
// import {MagnifyingGlass2_Stroke2_Corner0_Rounded as MagnifyingGlass} from '#/components/icons/MagnifyingGlass2'
// import {
//   Message_Stroke2_Corner0_Rounded as Message,
//   Message_Stroke2_Corner0_Rounded_Filled as MessageFilled,
// } from '#/components/icons/Message'
import {
  SettingsOpen as Settings,
  SettingsOpen_Fill as SettingsFilled,
} from '#/components/icons/SettingsGear2'
import {SwitchMaskIcon} from '#/components/icons/StakeIcons'
import {
  ProfileOpen as UserCircle,
  ProfileOpen_Fill as UserCircleFilled,
} from '#/components/icons/UserCircle'
// import {HomeTourExploreWrapper} from '#/tours/HomeTour'
import {router} from '../../../routes'

const NAV_ICON_WIDTH = 28

function ProfileCard() {
  const {currentAccount} = useSession()
  const theme = useTheme()
  const {isLoading, data: profile} = useProfileQuery({did: currentAccount!.did})
  const {isDesktop, isTablet} = useWebMediaQueries()
  const {_} = useLingui()
  const {openModal} = useModalControls()
  const size = 48

  const styles = getStyles(theme)

  return !isLoading && profile ? (
    <>
      <Link
        href={makeProfileLink({
          did: currentAccount!.did,
          handle: currentAccount!.handle,
        })}
        style={[styles.profileCard, !isDesktop && styles.profileCardTablet]}
        title={_(msg`My Profile`)}
        asAnchor>
        <UserAvatar
          avatar={profile.avatar}
          size={size}
          type={profile?.associated?.labeler ? 'labeler' : 'user'}
        />
      </Link>
      <View
        style={[
          styles.profileBottom,
          isTablet ? {} : {paddingLeft: 12, paddingBottom: 12},
        ]}>
        {isTablet ? null : (
          <View style={[a.flex_1]}>
            <Text
              style={[styles.profileDisplayName, theme.atoms.text]}
              numberOfLines={1}
              ellipsizeMode="tail">
              {profile?.displayName && profile?.displayName !== ''
                ? profile?.displayName
                : formatHandler(profile?.handle)}
            </Text>
            <Text
              style={styles.profileHandle}
              numberOfLines={1}
              ellipsizeMode="tail">
              @{formatHandler(profile?.handle)}
            </Text>
          </View>
        )}
        <Pressable
          accessibilityRole="button"
          testID="deskTopSwitchMaskBtn"
          onPress={() => {
            openModal({name: 'switch-mask'})
          }}
          style={[
            styles.switchBtn,
            isTablet ? {width: 35, height: 31} : {width: 70, height: 28},
          ]}>
          <SwitchMaskIcon primaryColor="#fff" />
          {isTablet ? null : (
            <Text style={{fontSize: 12, color: '#fff'}}>Switch</Text>
          )}
        </Pressable>
      </View>
    </>
  ) : (
    <View style={[styles.profileCard, !isDesktop && styles.profileCardTablet]}>
      <LoadingPlaceholder
        width={size}
        height={size}
        style={{borderRadius: size}}
      />
    </View>
  )
}

const HIDDEN_BACK_BNT_ROUTES = ['StarterPackWizard', 'StarterPackEdit']

function BackBtn() {
  const {isTablet} = useWebMediaQueries()
  const pal = usePalette('default')
  const theme = useTheme()
  const navigation = useNavigation<NavigationProp>()
  const {_} = useLingui()
  const styles = getStyles(theme)
  const shouldShow = useNavigationState(
    state =>
      !isStateAtTabRoot(state) &&
      !HIDDEN_BACK_BNT_ROUTES.includes(getCurrentRoute(state).name),
  )

  const onPressBack = React.useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('Home')
    }
  }, [navigation])

  if (!shouldShow || isTablet) {
    return <></>
  }
  return (
    <TouchableOpacity
      testID="viewHeaderBackOrMenuBtn"
      onPress={onPressBack}
      style={styles.backBtn}
      accessibilityRole="button"
      accessibilityLabel={_(msg`Go back`)}
      accessibilityHint="">
      <FontAwesomeIcon
        size={24}
        icon="angle-left"
        style={pal.text as FontAwesomeIconStyle}
      />
    </TouchableOpacity>
  )
}

interface NavItemProps {
  count?: string
  href: string
  icon: JSX.Element
  iconFilled: JSX.Element
  label: string
  onPressItem?: () => void
}
function NavItem({
  count,
  href,
  icon,
  iconFilled,
  label,
  onPressItem,
}: NavItemProps) {
  const pal = usePalette('default')
  const theme = useTheme()
  const {currentAccount} = useSession()
  const {isDesktop, isTablet} = useWebMediaQueries()
  const [pathName] = React.useMemo(() => {
    const res = router.matchPath(href)
    if (href === '/bookmarks') {
      return ['Bookmarks']
    } else {
      return res
    }
  }, [href])
  const currentRouteInfo = useNavigationState(state => {
    if (!state) {
      return {name: 'Home'}
    }
    return getCurrentRoute(state)
  })
  const styles = getStyles(theme)
  let isBookmarks =
    currentRouteInfo.name === 'Profile' &&
    (currentRouteInfo.params as CommonNavigatorParams['Profile']).name ===
      currentAccount?.handle &&
    (currentRouteInfo.params as CommonNavigatorParams['Profile']).tabName ===
      'Bookmarks'
  let isCurrent =
    pathName === 'Bookmarks'
      ? isBookmarks
      : currentRouteInfo.name === 'Profile'
      ? isTab(currentRouteInfo.name, pathName) &&
        ((currentRouteInfo.params as CommonNavigatorParams['Profile']).name ===
          currentAccount?.handle ||
          (currentRouteInfo.params as CommonNavigatorParams['Profile']).name ===
            'me') &&
        !(currentRouteInfo.params as CommonNavigatorParams['Profile']).tabName
      : isTab(currentRouteInfo.name, pathName)
  const {onPress} = useLinkProps({to: href})
  const onPressWrapped = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (e.ctrlKey || e.metaKey || e.altKey) {
        return
      }
      e.preventDefault()
      if (isCurrent) {
        emitSoftReset()
      } else {
        if (onPressItem) {
          onPressItem()
          return
        }
        onPress()
      }
    },
    [onPress, isCurrent, onPressItem],
  )

  return (
    <PressableWithHover
      style={styles.navItemWrapper}
      hoverStyle={pal.viewLight}
      // @ts-ignore the function signature differs on web -prf
      onPress={onPressWrapped}
      // @ts-ignore web only -prf
      href={href}
      dataSet={{noUnderline: 1}}
      accessibilityRole="tab"
      accessibilityLabel={label}
      accessibilityHint="">
      <View
        style={[
          styles.navItemIconWrapper,
          isTablet && styles.navItemIconWrapperTablet,
        ]}>
        {isCurrent ? iconFilled : icon}
        {typeof count === 'string' && count ? (
          <Text
            type="button"
            style={[
              styles.navItemCount,
              isTablet && styles.navItemCountTablet,
            ]}>
            {count}
          </Text>
        ) : null}
      </View>
      {isDesktop && (
        <Text type="title" style={[isCurrent ? s.bold : s.normal, pal.text]}>
          {label}
        </Text>
      )}
    </PressableWithHover>
  )
}

function ComposeBtn() {
  const {currentAccount} = useSession()
  const {getState} = useNavigation()
  const {openComposer} = useComposerControls()
  const {_} = useLingui()
  const theme = useTheme()
  const {isTablet} = useWebMediaQueries()
  const [isFetchingHandle, setIsFetchingHandle] = React.useState(false)
  const fetchHandle = useFetchHandle()
  const {setShowSpillToolTip} = usePostPreState()

  const getProfileHandle = async () => {
    const routes = getState()?.routes
    const currentRoute = routes?.[routes?.length - 1]

    if (currentRoute?.name === 'Profile') {
      let handle: string | undefined = (
        currentRoute.params as CommonNavigatorParams['Profile']
      ).name

      if (handle.startsWith('did:')) {
        try {
          setIsFetchingHandle(true)
          handle = await fetchHandle(handle)
        } catch (e) {
          handle = undefined
        } finally {
          setIsFetchingHandle(false)
        }
      }

      if (
        !handle ||
        handle === currentAccount?.handle ||
        handle === 'me' ||
        isInvalidHandle(handle)
      )
        return undefined

      return handle
    }

    return undefined
  }

  const {openModal} = useModalControls(),
    selectedFeed = useSelectedFeed(),
    {isBoundTw} = useTwStore(),
    {bind: hasBindTelegram} = useTgStore()

  const validatePublish = useCallback(
    async (opts: ComposerOpts) => {
      if (selectedFeed?.includes('circle')) {
        if (!hasBindTelegram) {
          openModal({name: 'telegram'})
          return
        }
      } else {
        if (!hasBindTelegram && !isBoundTw) {
          openModal({name: 'link-social'})
          return
        }
      }
      openComposer(opts)
    },
    [selectedFeed, openComposer, hasBindTelegram, openModal, isBoundTw],
  )

  const onPressCompose = async () =>
    validatePublish({mention: await getProfileHandle()})

  // if (isTablet) {
  //   return null
  // }
  const styles = getStyles(theme)
  const spillRef = React.useRef<any>(null)
  const popoverAnchorRect = spillRef?.current?.getBoundingClientRect()

  return (
    <View style={styles.newPostBtnContainer}>
      <TouchableOpacity
        disabled={isFetchingHandle}
        style={[styles.newPostBtn, isTablet && styles.smallNewPostBtn]}
        onPress={() => {
          onPressCompose()
          setShowSpillToolTip(false)
        }}
        ref={spillRef}
        accessibilityRole="button"
        accessibilityLabel={_(msg`New post`)}
        accessibilityHint="">
        <View style={styles.newPostBtnIconWrapper}>
          <EditBig width={19} style={styles.newPostBtnLabel} />
        </View>
        {isTablet ? null : (
          <Text type="button" style={styles.newPostBtnLabel}>
            <Trans context="action">Spill</Trans>
          </Text>
        )}
      </TouchableOpacity>
      <SpillPopover
        fromRect={{
          x: popoverAnchorRect?.x + 10,
          y: popoverAnchorRect?.y + (isTablet ? 25 : 20),
          width: popoverAnchorRect?.width,
          height: popoverAnchorRect?.height,
        }}
      />
    </View>
  )
}

export function SpillPopover({
  fromRect,
  spillRef,
}: {
  fromRect: any
  spillRef?: any
}) {
  const t = useTheme()
  const styles = getStyles(t)
  const {isMobile} = useWebMediaQueries()

  const {showSpillToolTip, setShowSpillToolTip} = usePostPreState()
  const [newFromRect, setNewFromRect] = React.useState<any>(fromRect)
  React.useEffect(() => {
    if (showSpillToolTip && spillRef?.current && isMobile) {
      const popoverAnchorRect = spillRef?.current?.getBoundingClientRect()
      setNewFromRect({
        x: popoverAnchorRect?.x,
        y: popoverAnchorRect?.y - 50,
        width: popoverAnchorRect?.width,
        height: popoverAnchorRect?.height,
      })
    }
  }, [showSpillToolTip, spillRef, isMobile])

  return (
    <Popover
      visible={showSpillToolTip}
      arrowStyle={{borderTopColor: t.palette.primary}}
      backgroundStyle={{backgroundColor: '#00000099'}}
      onClose={() => {
        setShowSpillToolTip(false)
      }}
      onDismiss={() => {
        setShowSpillToolTip(false)
      }}
      fromRect={isMobile ? newFromRect : fromRect}
      calculateStatusBar={false}
      supportedOrientations={['portrait', 'landscape']}
      contentStyle={{
        padding: 0,
        borderRadius: 18,
        backgroundColor: t.palette.primary,
      }}>
      <View
        style={[
          a.align_center,
          a.justify_center,
          {width: 350, height: 83, borderRadius: 18},
          {backgroundColor: t.palette.primary},
        ]}>
        <Text
          style={[
            a.text_md,
            a.font_semibold,
            a.text_center,
            {color: t.palette.black},
          ]}>
          <Trans>Hey, do you want to spill something?</Trans>
        </Text>
        <TouchableOpacity
          onPress={() => setShowSpillToolTip(false)}
          accessibilityRole="button"
          accessibilityLabel={'Close'}
          accessibilityHint=""
          style={{position: 'absolute', top: 10, right: 10}}>
          <IconCircle
            style={[styles.closeBtn]}
            icon={Close}
            iconStyle={{color: ''}}
            size="xs"
          />
        </TouchableOpacity>
      </View>
    </Popover>
  )
}

// function ChatNavItem() {
//   const pal = usePalette('default')
//   const {_} = useLingui()
//   const numUnreadMessages = useUnreadMessageCount()

//   return (
//     <NavItem
//       href="/messages"
//       count={numUnreadMessages.numUnread}
//       icon={<Message style={pal.text} width={NAV_ICON_WIDTH} />}
//       iconFilled={<MessageFilled style={pal.text} width={NAV_ICON_WIDTH} />}
//       label={_(msg`Chat`)}
//     />
//   )
// }

// function BookMarksNavItem() {
//   const navigation = useNavigation<NavigationProp>()
//   const {_} = useLingui()
//   const {currentAccount} = useSession()
//   const {setTabName} = useProfileStore()

//   return (
//     <NavItem
//       href="/bookmarks"
//       onPressItem={() => {
//         setTabName('Bookmarks')
//         navigation.navigate('Profile', {
//           name: currentAccount!.handle,
//           tabName: 'Bookmarks',
//         })
//       }}
//       icon={<BookMarkIcon size={NAV_ICON_WIDTH} />}
//       iconFilled={
//         <BookMarkIcon
//           size={NAV_ICON_WIDTH}
//           active={true}
//           leftNavColor={'#000'}
//         />
//       }
//       label={_(msg`Bookmarks`)}
//     />
//   )
// }

function CircleNavItem() {
  const navigation = useNavigation<NavigationProp>()
  const {_} = useLingui()
  const pal = usePalette('default')
  const {bind: hasBindTelegram} = useTgStore(),
    {openModal} = useModalControls()

  return (
    <NavItem
      href="/connections"
      onPressItem={() => {
        if (hasBindTelegram) {
          navigation.navigate('Connections')
        } else {
          openModal({name: 'telegram'})
        }
      }}
      icon={<Circle width={NAV_ICON_WIDTH} style={pal.text} />}
      iconFilled={<CircleFill width={NAV_ICON_WIDTH} style={pal.text} />}
      label={_(msg`Connections`)}
    />
  )
}

function InviteNavItem() {
  // const navigation = useNavigation<NavigationProp>()
  const {_} = useLingui()
  // const {currentAccount} = useSession()
  // const {bind: hasBindTelegram} = useTgStore(),
  //   {openModal} = useModalControls()

  return (
    <NavItem
      href="/task"
      // onPressItem={() => {
      //   if (hasBindTelegram) {
      //     openModal({
      //       name: 'telegram',
      //       currentStep: TelegramBindStep.SelectContact,
      //     })
      //   } else {
      //     openModal({name: 'telegram'})
      //   }
      // }}
      icon={<InviteIcon size={NAV_ICON_WIDTH} />}
      iconFilled={
        <InviteIcon size={NAV_ICON_WIDTH} active={true} leftNavColor={'#000'} />
      }
      label={_(msg`Rewards`)}
    />
  )
}

export function DesktopLeftNav() {
  const {hasSession, currentAccount} = useSession()
  const pal = usePalette('default')
  const theme = useTheme()
  const {_} = useLingui()
  const {isDesktop, isTablet} = useWebMediaQueries()
  const numUnreadNotifications = useUnreadNotifications()

  if (!hasSession && !isDesktop) {
    return null
  }

  const styles = getStyles(theme)

  return (
    <View
      style={[
        styles.leftNav,
        isTablet && styles.leftNavTablet,
        pal.view,
        pal.border,
      ]}>
      {hasSession ? (
        <ProfileCard />
      ) : isDesktop ? (
        <View style={{paddingHorizontal: 12}}>
          <NavSignupCard />
        </View>
      ) : null}

      {hasSession && (
        <>
          <BackBtn />

          <NavItem
            href="/"
            icon={<Home width={NAV_ICON_WIDTH} style={pal.text} />}
            iconFilled={<HomeFilled width={NAV_ICON_WIDTH} style={pal.text} />}
            label={_(msg`Home`)}
          />
          <NavItem
            href="/notifications"
            count={numUnreadNotifications}
            icon={<Bell width={NAV_ICON_WIDTH} style={pal.text} />}
            iconFilled={<BellFilled width={NAV_ICON_WIDTH} style={pal.text} />}
            label={_(msg`Notifications`)}
          />
          {/* <NavItem
            href={currentAccount ? makeAssetsLink() : '/'}
            icon={<AssetsIcon size={NAV_ICON_WIDTH} />}
            iconFilled={<AssetsIcon size={NAV_ICON_WIDTH} active={true} />}
            label={_(msg`Assets`)}
          /> */}
          <NavItem
            href={currentAccount ? '/profile/me' : '/'}
            icon={<UserCircle width={NAV_ICON_WIDTH} style={pal.text} />}
            iconFilled={
              <UserCircleFilled width={NAV_ICON_WIDTH} style={pal.text} />
            }
            label={_(msg`Profile`)}
          />
          <CircleNavItem />
          <NavItem
            href={'/mask'}
            icon={<Mask width={NAV_ICON_WIDTH} style={pal.text} />}
            iconFilled={<MaskFill width={NAV_ICON_WIDTH} style={pal.text} />}
            label={_(msg`Mask`)}
          />

          {/* <BookMarksNavItem /> */}
          {/* <HomeTourExploreWrapper>
            <NavItem
              href="/search"
              icon={<MagnifyingGlass style={pal.text} width={NAV_ICON_WIDTH} />}
              iconFilled={
                <MagnifyingGlassFilled
                  style={pal.text}
                  width={NAV_ICON_WIDTH}
                />
              }
              label={_(msg`Search`)}
            />
          </HomeTourExploreWrapper> */}

          {/* <ChatNavItem /> */}

          <NavItem
            href="/settings"
            icon={<Settings width={NAV_ICON_WIDTH} style={pal.text} />}
            iconFilled={
              <SettingsFilled width={NAV_ICON_WIDTH} style={pal.text} />
            }
            label={_(msg`Settings`)}
          />
          <InviteNavItem />

          <ComposeBtn />
        </>
      )}
    </View>
  )
}

const getStyles = (theme: Theme) => {
  return StyleSheet.create({
    leftNav: {
      // @ts-ignore web only
      position: 'fixed',
      top: 10,
      // @ts-ignore web only
      left: 'calc(50vw - 300px - 220px - 20px)',
      width: 220,
      // @ts-ignore web only
      maxHeight: 'calc(100vh - 10px)',
      overflowY: 'auto',
    },
    leftNavTablet: {
      top: 0,
      left: 0,
      right: 'auto',
      borderRightWidth: 1,
      height: '100%',
      width: 76,
      alignItems: 'center',
    },

    profileCard: {
      marginVertical: 10,
      width: 90,
      paddingLeft: 12,
    },
    profileCardTablet: {
      width: 70,
    },
    profileDisplayName: {
      fontSize: 18,
      fontWeight: '600',
    },
    profileHandle: {
      fontSize: 12,
      color: '#979797',
    },
    profileBottom: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    switchBtn: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      backgroundColor: '#5E6272',
      borderRadius: 40,
    },

    backBtn: {
      position: 'absolute',
      top: 12,
      right: 12,
      width: 30,
      height: 30,
    },

    navItemWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 12,
      padding: 12,
      borderRadius: 8,
      gap: 10,
    },
    navItemIconWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      width: 28,
      height: 24,
      marginTop: 2,
      zIndex: 1,
    },
    navItemIconWrapperTablet: {
      width: 40,
      height: 40,
    },
    navItemCount: {
      position: 'absolute',
      top: 0,
      left: 15,
      backgroundColor: colors.bubble,
      color: colors.white,
      fontSize: 12,
      fontWeight: 'bold',
      paddingHorizontal: 4,
      borderRadius: 6,
    },
    navItemCountTablet: {
      left: 18,
      fontSize: 14,
    },

    newPostBtnContainer: {
      flexDirection: 'row',
    },
    newPostBtn: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 24,
      paddingTop: 10,
      paddingBottom: 12, // visually aligns the text vertically inside the button
      paddingLeft: 16,
      paddingRight: 18, // looks nicer like this
      backgroundColor: theme.palette.primary,
      marginLeft: 12,
      marginTop: 20,
      marginBottom: 10,
      gap: 8,
    },
    smallNewPostBtn: {
      paddingTop: 5,
      paddingBottom: 5, // visually aligns the text vertically inside the button
      paddingLeft: 8,
      paddingRight: 8, // looks nicer like this
      marginLeft: 0,
    },
    newPostBtnIconWrapper: {
      marginTop: 2, // aligns the icon visually with the text
    },
    newPostBtnLabel: {
      color: 'black',
      fontSize: 16,
      fontWeight: '600',
    },
    closeBtn: {
      width: 20,
      height: 20,
      backgroundColor: '#00000080',
    },
  })
}
