interface CircleItem {
  image: any
  color: string
}
interface ICircleConfig {
  dark: CircleItem
  light: CircleItem
}

const CircleFeedMap = new Map<string, ICircleConfig>()

let index = 0

const backgrounds = [
  {
    dark: require('../../../../assets/imgs/list_card_0.png'),
    light: require('../../../../assets/imgs/list_card_light_0.png'),
  },
  {
    dark: require('../../../../assets/imgs/list_card_1.png'),
    light: require('../../../../assets/imgs/list_card_light_1.png'),
  },
  {
    dark: require('../../../../assets/imgs/list_card_2.png'),
    light: require('../../../../assets/imgs/list_card_light_2.png'),
  },
  {
    dark: require('../../../../assets/imgs/list_card_3.png'),
    light: require('../../../../assets/imgs/list_card_light_3.png'),
  },
  {
    dark: require('../../../../assets/imgs/list_card_4.png'),
    light: require('../../../../assets/imgs/list_card_light_4.png'),
  },
]

export const colors = [
  {
    dark: '#FFE371',
    light: '#FFF7D5',
  },
  {
    dark: '#FFACAC',
    light: '#FBE4E1',
  },
  {
    dark: '#C1F0A1',
    light: '#E7FBE1',
  },
  {
    dark: '#9BEAFB',
    light: '#E0F2FF',
  },
  {
    dark: '#F6F6F6',
    light: '#F6F6F6',
  },
]

const cards = backgrounds.map((item, i) => {
  return {
    dark: {
      image: item.dark,
      color: colors[i].dark,
    },
    light: {
      image: item.light,
      color: colors[i].light,
    },
  }
})

export const FromOfficialCard = {
  dark: {
    image: require('../../../../assets/imgs/list_card_1.png'),
    color: '#FFACAC',
  },
  light: {
    image: require('../../../../assets/imgs/list_card_light_1.png'),
    color: '#FBE4E1',
  },
}

export function getCircleItemCard(did: string) {
  if (CircleFeedMap.has(did)) {
    return CircleFeedMap.get(did)!
  }
  const currentIndex = index++ % cards.length
  CircleFeedMap.set(did, cards[currentIndex])
  return cards[currentIndex]
}
