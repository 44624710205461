import {useCallback, useEffect, useState} from 'react'
import axios from 'axios'

import {useAgent} from '#/state/session'
export function fuzzyMatch(matchText: string, searchInput: string): boolean {
  if (!searchInput) return false
  const lowerMatchText = matchText.toLowerCase()
  const lowerSearchInput = searchInput.toLowerCase()
  let matchIndex = 0
  let searchInputIndex = 0

  while (
    matchIndex < lowerMatchText.length &&
    searchInputIndex < lowerSearchInput.length
  ) {
    if (lowerMatchText[matchIndex] === lowerSearchInput[searchInputIndex]) {
      searchInputIndex++
    }
    matchIndex++
  }
  return searchInputIndex === lowerSearchInput.length
}

const googleKey = 'AIzaSyAD6G43xYxViKYXw4JcmunKzH6cy3jRyXo'

export async function TranslateText(str: string | string[], target?: string) {
  const sourceType = typeof str
  try {
    const response = await axios.post(
      `https://translation.googleapis.com/language/translate/v2?key=${googleKey}`,
      {
        q: str || '',
        target: target || 'en',
        format: 'text',
      },
    )
    if (sourceType === 'string') {
      return response.data.data.translations[0].translatedText
    } else {
      return response.data.data.translations.map(
        (item: any) => item.translatedText,
      )
    }
  } catch (error) {
    console.error('Error translating text:', error.message)
    return ''
  }
}

export const DetectLanguage = async (text: string) => {
  try {
    const response = await axios.post(
      `https://translation.googleapis.com/language/translate/v2/detect?key=${googleKey}`,
      null,
      {
        params: {
          q: text,
        },
      },
    )

    const language = response.data.data.detections[0][0].language
    return language
  } catch (error) {
    console.error('Error detecting language:', error.message)
    return ''
  }
}

export function useCheckVersion() {
  const agent = useAgent()
  const [newVersion, setNewVersion] = useState('')
  const [isVersionOutdated, setIsVersionOutdated] = useState(false)

  const refreshPage = useCallback(() => {
    localStorage.setItem('sipz_version', newVersion)
    window.location.href = window.location.origin + '/'
  }, [newVersion])

  useEffect(() => {
    const checkVersion = () => {
      agent.com.atproto.server
        .getTranslation({
          uri: 'sipzVersion',
          lang: 'en',
        })
        .then(sdlTranslate => {
          if (sdlTranslate.data.ok) {
            const version = sdlTranslate.data.translation as 'string'
            setNewVersion(version)
            const storedVersion = localStorage.getItem('sipz_version')
            if (storedVersion && storedVersion !== version) {
              setIsVersionOutdated(true)
            }
            if (!storedVersion) {
              localStorage.setItem('sipz_version', version)
            }
          }
        })
        .catch(error => {
          console.error('Error reading version.txt:', error)
        })
    }
    checkVersion()
    const timer = setInterval(() => {
      checkVersion()
    }, 1000 * 60 * 5)
    return () => {
      clearInterval(timer)
    }
  }, [agent.com.atproto.server])

  return {isVersionOutdated, refreshPage}
}
