import React, {useMemo} from 'react'
import {StyleSheet, View} from 'react-native'

import {batchedUpdates} from '#/lib/batchedUpdates'
import {useNonReactiveCallback} from '#/lib/hooks/useNonReactiveCallback'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {FeedPostSliceItem} from '#/state/queries/post-feed'
import {ReFreshContainer} from '../util/ReFreshContainer'
import {useNewPostStore} from '../util/sdlStore/newPostStore'
import {CenteredView} from '../util/Views'
import {FeedItem} from './Feed'
import {MemoCard} from './MemoCard'

interface ICircleFeedList {
  data: FeedItem[]
  onEndReached: (data: {distanceFromEnd: number}) => void
  refreshing: boolean
  onRefresh: () => void
}

export default function CircleFeedList({
  data,
  onEndReached,
  refreshing,
  onRefresh,
}: ICircleFeedList) {
  const arrayData = useMemo((): FeedPostSliceItem[] => {
    const array = data.map(ele => {
      if (ele.type === 'slice') {
        return ele.slice.items
      } else {
        return []
      }
    })
    return array.flat()
  }, [data])
  const {isWideList} = useWebMediaQueries()

  const {resetCircle} = useNewPostStore()

  return (
    <CenteredView style={{height: '100%'}}>
      <ReFreshContainer
        refreshing={refreshing}
        onRefresh={() => {
          onRefresh()
          resetCircle()
        }}>
        <View style={[styles.container]}>
          {arrayData.map((item, index) => (
            <MemoCard
              index={isWideList ? index : undefined}
              key={item._reactKey}
              post={item.post}
              isLocal={item?.isLocal}
              record={item.record}
              style={{width: isWideList ? '50%' : '100%'}}
            />
          ))}
          <View style={{width: 23, height: 90}} />
          <Visibility
            onVisibleChange={isVisible => {
              isVisible && onEndReached({distanceFromEnd: 0})
            }}
          />
        </View>
      </ReFreshContainer>
    </CenteredView>
  )
}

let Visibility = ({
  onVisibleChange,
}: {
  onVisibleChange: (isVisible: boolean) => void
}): React.ReactNode => {
  const tailRef = React.useRef(null)
  const isIntersecting = React.useRef(false)

  const handleIntersection = useNonReactiveCallback(
    (entries: IntersectionObserverEntry[]) => {
      batchedUpdates(() => {
        entries.forEach(entry => {
          if (entry.isIntersecting !== isIntersecting.current) {
            isIntersecting.current = entry.isIntersecting
            onVisibleChange(entry.isIntersecting)
          }
        })
      })
    },
  )

  React.useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: `0px`,
    })
    const tail: Element | null = tailRef.current!
    observer.observe(tail)
    return () => {
      observer.unobserve(tail)
    }
  }, [handleIntersection])

  return <View ref={tailRef} style={styles.visibilityDetector} />
}
Visibility = React.memo(Visibility)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
  },
  listContainer: {
    justifyContent: 'flex-start',
    paddingTop: 30,
    marginBottom: 100,
  },
  item: {
    flex: 1,
    margin: 5,
    height: 100,
    backgroundColor: '#4CAF50',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#fff',
  },
  visibilityDetector: {
    height: 80,
    width: 50,
    pointerEvents: 'none',
    zIndex: -1,
    position: 'absolute',
    bottom: 200,
    left: 39,
  },
})
