import React from 'react'
import {StyleSheet, Text, TouchableOpacity} from 'react-native'
import {Trans} from '@lingui/macro'

import {useTheme} from '#/alf'

interface ISdlButton {
  text: string
  onPress: () => void
  type?: 'primary' | 'secondary'
  disabled?: boolean
}

export function SdlButton({
  text,
  disabled,
  onPress,
  type = 'primary',
}: ISdlButton) {
  const t = useTheme()

  const dynamicStyle = {
    backgroundColor: type === 'primary' ? t.palette.primary : t.palette.gray,
    color: 'black',
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      accessibilityRole="button"
      style={{padding: 2, flex: 1}}
      // style={[
      //   a.mx_xl,
      //   a.mb_xl,
      //   {
      //     shadowRadius: 16,
      //     shadowOpacity: 0.5,
      //     shadowColor: '#FCD010',
      //     elevation: 16,
      //     padding: 2,
      //   },
      // ]}
      onPress={onPress}>
      <Text style={[styles.text, dynamicStyle]}>
        <Trans>{text}</Trans>
      </Text>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
    borderRadius: 100,
    padding: 14.5,
  },
})
